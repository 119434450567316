<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{padding:'0'}">
      <div class="tb_group_cardtitle">
        <div class="iconfont icon-LocalSource" style="font-weight:700">&nbsp;IPTV节目组</div>
        <i
          class="el-icon-arrow-up"
          v-if="tbGroupShow"
          @click="tbGroupShow=!tbGroupShow"
          style="cursor:pointer"
        >关闭</i>
        <i
          class="el-icon-arrow-down"
          v-else
          @click="tbGroupShow=!tbGroupShow"
          style="cursor:pointer"
        >展开</i>
      </div>

      <el-collapse-transition>
        <div v-show="tbGroupShow">
          <div class="transition-box">
            <div class="box">
              <div
                :class=" default_group==group.liveGroupId?'tv_group_card tv_group_card_select':'tv_group_card' "
                v-for="(group,index) in groupList"
                :key="index"
              >
                <label class="delGroupClass" @click="onSubmitDelGroup(group)">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF"></i>
                </label>
                <div class="tv_group_view">
                  <div @click="selectedGroup(group)">
                    <div class="tv_group_top">
                      <span>{{group.liveGroupName}}</span>
                    </div>
                    <div class="tv_group_middle">
                      <div style="width:50%">创建人:{{group.nickName==''?group.mobile:group.nickName}}</div>
                      <div style="width:50%;text-align:right">节目源数量:{{group.channelCount}}个</div>
                    </div>
                  </div>
                  <div class="tv_group_bottom">
                    <div>
                      <el-button type="text" size="small" @click="openEditGroup(group)">编辑</el-button>
                      <el-button
                        type="text"
                        size="small"
                        @click="applicationGroup(group)"
                        v-if="group.isApply !='apply'"
                      >应用</el-button>
                      <el-button type="text" size="small" v-else>已应用</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tv_group_card" @click="dialogAddGroup=true">
                <div class="tv_group_view">
                  <div class="tv_group_add_view">
                    <i class="el-icon-plus tv_group_add_icon"></i>
                  </div>
                  <div class="tv_group_bottom">
                    <el-button type="text" size="small" style="color:#8c939d">添加节目组</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </el-card>

    <!-- 添加节目组 -->
    <el-dialog
      :visible.sync="dialogAddGroup"
      :close-on-click-modal="false"
      width="25%"
      @closed="closeDialogAddGroup"
    >
      <span slot="title">添加节目组</span>
      <el-form
        label-width="50px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
        @submit.native.prevent
      >
        <el-form-item label="名称">
          <el-input v-model="liveGroupName" placeholder="请输入节目组名称" maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddGroup=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddGroup">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑节目组 -->
    <el-dialog :visible.sync="dialogEditGroup" :close-on-click-modal="false" width="35%">
      <span slot="title">编辑节目组</span>
      <el-form
        :model="editGroupForm"
        ref="editGroupForm"
        label-width="80px"
        size="small"
        style="padding:20px"
      >
        <el-form-item label="名称">
          <el-input v-model="editGroupForm.liveGroupName" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="共享给">
          <el-input v-model="editGroupForm.phone" placeholder="请输入共享用户的手机号">
            <template slot="append">
              <el-button style="color:#5e72f9;width:100px" @click="onSubmitShareGroup">发送</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="共享列表">
          <div class="down-tree">
            <el-tag
              v-for="groupUser in liveGroupUserList"
              :key="groupUser.userId"
              effect="light"
              closable
              style="margin:5px 10px"
              @close="handleGroupClose(groupUser)"
            >{{groupUser.mobile}}</el-tag>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditGroup=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditGroup">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 节目源列表 -->
    <el-card shadow="never" style="margin-top:10px">
      <div slot="header" class="iconfont icon-SourceList">&nbsp;IPTV节目源列表</div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddChannel=true">添加节目源</el-button>
          <el-button size="small" plain @click="dialogCategory=true">分类管理</el-button>
          <el-button
            size="small"
            plain
            @click="batchDelChannel"
            v-show="selectionLineChannelData.length>0"
          >批量删除</el-button>
        </div>
        <div>
          <el-input
            v-model="search"
            placeholder="请输入查询条件"
            suffix-icon="el-icon-search"
            style="width:300px"
            size="small"
            @change="initLoadChannel(default_group)"
          ></el-input>
        </div>
      </div>
      <el-table
        ref="table"
        :data="channelList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeChannel"
        row-key="channelId"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" size="mini" @blur="blurChannelSort(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="channelName" label="频道名称" width="200"></el-table-column>
        <el-table-column prop="channelTypeId" label="频道类型" width="200">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.channelTypeId"
              placeholder="请选择"
              @change="changeChannelType(scope.row)"
              style="width:100%"
              size="mini"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.channelTypeId"
                :label="item.channelTypeName"
                :value="item.channelTypeId"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="channelUrl" label="节目源地址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="channelState" label="启用状态" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.channelState"
              active-value="on"
              inactive-value="off"
              @change="changeChannelState(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="openEditChaneel(scope.row)">编辑</el-button>
            <el-button type="text" @click="onSubmitDelChannel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="channelList.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 分类管理 -->
    <el-dialog :visible.sync="dialogCategory" :close-on-click-modal="false" width="40%">
      <span slot="title">分类管理</span>
      <div style="margin:10px 0">
        <el-button type="primary" size="small" @click="dialogAddCategory=true">新增分类</el-button>
        <el-button type="primary" size="small" @click="batchDelCategory">批量删除</el-button>
      </div>
      <el-table
        :data="categoryList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        border
        size="mini"
        @selection-change="selectionLineChangeCategory"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" size="mini" @blur="blurCategorySort(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="channelTypeName" label="中文名称">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-if="scope.row.isCategoryNameSelected"
              v-model="scope.row.channelTypeName"
              ref="editInput"
              @focus="focusCategoryName(scope.row)"
              @blur="blurCategoryName(scope.row)"
              v-focus
            ></el-input>
            <span v-else>
              {{ scope.row.channelTypeName }}
              <i
                class="el-icon-edit-outline"
                style="color:#5e72f9;font-size:15px;margin-left:10px;cursor:pointer;vertical-align:middle"
                @click="cellCategoryClick(scope.row)"
              ></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="channelTypeEnName" label="英文名称"></el-table-column>
        <el-table-column prop="state" label="启用状态" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-value="on"
              inactive-value="off"
              @change="changeCategoryState(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="onSubmitDelCategory(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding:20px 0;text-align:right">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          :total="categoryList.length"
          :page-size="categoryPageSize"
          :current-page="categoryPage"
          @current-change="handleCategoryCurrentChange"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 添加分类 -->
    <el-dialog
      :visible.sync="dialogAddCategory"
      :close-on-click-modal="false"
      width="25%"
      @closed="closeDialogAddCategory"
    >
      <span slot="title">新增分类</span>
      <el-form
        :model="categoryForm"
        ref="categoryForm"
        label-width="80px"
        size="small"
        style="padding:20px"
      >
        <el-form-item label="中文名称">
          <el-input v-model="categoryForm.channelTypeName" maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddCategory=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddCategory">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加节目源 -->
    <el-dialog
      :visible.sync="dialogAddChannel"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddChannel"
    >
      <span slot="title">新增节目源</span>
      <el-form
        :model="channelForm"
        ref="channelForm"
        label-width="100px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-row :gutter="0">
          <el-col :span="12" :offset="0">
            <el-form-item
              label="频道名称"
              prop="channelName"
              :rules="{ required: true, message: '请输入频道名称', trigger: 'blur' }"
            >
              <el-input
                v-model="channelForm.channelName"
                placeholder="请输入频道名称"
                maxlength="11"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item
              label="频道类型"
              prop="channelTypeId"
              :rules="{ required: true, message: '请选择频道类型', trigger: 'change' }"
            >
              <el-select
                v-model="channelForm.channelTypeId"
                placeholder="请选择频道类型"
                style="width:100%"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.channelTypeId"
                  :label="item.channelTypeName"
                  :value="item.channelTypeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="0">
          <el-col :span="12" :offset="0">
            <el-form-item label="是否启用">
              <el-radio-group v-model="channelForm.channelState" size="mini">
                <el-radio-button label="on">启用</el-radio-button>
                <el-radio-button label="off">禁用</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="排序" prop="sort">
              <el-input v-model="channelForm.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          v-for="(domains,index) in channelForm.channelUrlList"
          :key="index"
          :label="index==0?'节目源地址':'备用源'+index"
        >
          <el-input v-model="domains.value" type="textarea" placeholder="请输入节目源地址"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="addDomain">添加一条备用源</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddChannel=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddChannel">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改节目源 -->
    <el-dialog
      :visible.sync="dialogEditChannel"
      :close-on-click-modal="false"
      width="45%"
    >
      <span slot="title">编辑节目源</span>
      <el-form
        :model="editChannelForm"
        ref="editChannelForm"
        label-width="100px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-row :gutter="0">
          <el-col :span="12" :offset="0">
            <el-form-item
              label="频道名称"
              prop="channelName"
              :rules="{ required: true, message: '请输入频道名称', trigger: 'blur' }"
            >
              <el-input
                v-model="editChannelForm.channelName"
                placeholder="请输入频道名称"
                maxlength="11"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item
              label="频道类型"
              prop="channelTypeId"
              :rules="{ required: true, message: '请选择频道类型', trigger: 'change' }"
            >
              <el-select
                v-model="editChannelForm.channelTypeId"
                placeholder="请选择频道类型"
                style="width:100%"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.channelTypeId"
                  :label="item.channelTypeName"
                  :value="item.channelTypeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="0">
          <el-col :span="12" :offset="0">
            <el-form-item label="是否启用">
              <el-radio-group v-model="editChannelForm.channelState" size="mini">
                <el-radio-button label="on">启用</el-radio-button>
                <el-radio-button label="off">禁用</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="排序" prop="sort">
              <el-input v-model="editChannelForm.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          v-for="(edit,index) in editChannelForm.channelUrlList"
          :key="index"
          :label="index==0?'节目源地址':'备用源'+index"
          class="localWorkAddress_item"
        >
          <el-input v-model="edit.value" type="textarea" :rows="4"></el-input>
          <div class="localWorkAddress_operation_btn">
            <div v-if="index !=0" @click="upSortChannel(edit)">
              <el-tag type="success" size="mini" effect="dark">
                <i class="el-icon-top"></i>
              </el-tag>
            </div>
            <div
              v-if="index !=editChannelForm.channelUrlList.length-1"
              @click="downSortChannel(edit)"
            >
              <el-tag type="success" size="mini" effect="dark">
                <i class="el-icon-bottom"></i>
              </el-tag>
            </div>

            <div @click="delChildrenChannel(edit)">
              <el-tag type="danger" size="mini" effect="dark">
                <i class="el-icon-delete"></i>
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="dialogEditInAddDomain">添加一条备用源</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditChannel=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditChannel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getNetworkSourceGroupApi,
  addNetworkSourceGroupApi,
  editNetworkSourceGroupApi,
  delNetworkSourceGroupApi,
  applyNetworkSourceGroupApi,
  getGroupToShareUserListApi,
  shareGroupApi,
  delShareGroupApi,
  getChannelCategoryApi,
  addChannelCategoryApi,
  editChannelCategoryApi,
  delChannelCategoryApi,
  getChannelApi,
  addChannelApi,
  editChannelApi,
  delChannelApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading: true,
      tbGroupShow: true, //控制节目组的展开关闭
      search: "", //查询条件
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      groupList: [], //节目组集合
      default_group: "", //选中的节目组
      dialogAddGroup: false, //添加节目组窗口
      dialogEditGroup: false, //修改节目组窗口
      editGroupForm: {},
      liveGroupName: "", //添加节目组使用参数
      liveGroupUserList: [], //选中的节目组中分享用户集合
      dialogCategory: false, //分类管理窗口
      dialogAddCategory: false, //添加分类窗口
      categoryList: [], //分类集合
      categoryPageSize: 10, //分类一页显示多少条
      categoryPage: 1, //分类当前页
      categoryForm: {
        channelTypeName: "",
        liveGroupId: ""
      },
      selectionLineCategoryData: [], //分类勾选集合
      channelList: [], //节目源集合
      dialogAddChannel: false, //添加节目源窗口
      dialogEditChannel: false, //修改节目源窗口
      channelForm: {
        channelName: "",
        channelTypeId: "",
        channelState: "on",
        sort: "1",
        channelUrl: "",
        channelUrlList: [{ value: "" }]
      },
      editChannelForm: {
        channelName: "",
        channelTypeId: "",
        channelState: "",
        sort: "",
        channelUrl: "",
        channelUrlList: [{ value: "" }]
      },
      selectionLineChannelData: [], //节目源勾选集合
      isShow: false
    };
  },
  //自定义指令对文本框添加焦点
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  created() {
    this.firstInitLoadGroup();
  },
  methods: {
    //第一次加载节目组
    firstInitLoadGroup() {
      getNetworkSourceGroupApi({
        groupId: this.placesInfo.groupId,
        type: "local"
      }).then(res => {
        this.loading = false;
        if (res.data.length > 0) {
          this.groupList = res.data;
          //默认选择第一个节目组
          this.default_group = res.data[0].liveGroupId;
          this.initChannelCategory(this.default_group);
          this.initLoadChannel(this.default_group);
        }
      });
    },
    //初始化节目组
    initLoadGroup() {
      getNetworkSourceGroupApi({
        groupId: this.placesInfo.groupId,
        type: "local"
      }).then(res => {
        if (res) {
          this.groupList = res.data;
          this.initLoadChannel(this.default_group);
          if (this.groupList.length == 1) {
            this.default_group = res.data[0].liveGroupId;
          }
        }
      });
    },
    //初始化节目源
    initLoadChannel(id) {
      getChannelApi({ liveGroupId: id, params: this.search }).then(res => {
        if (res) {
          this.channelList = res.data;
        }
      });
    },
    //查询分类
    initChannelCategory(id) {
      getChannelCategoryApi({ liveGroupId: id }).then(res => {
        if (res) {
          this.categoryList = res.data.map(item => {
            return { ...item, isCategoryNameSelected: false };
          });
        }
      });
    },
    //添加节目源
    onSubmitAddChannel() {
      this.$refs["channelForm"].validate(valid => {
        if (valid) {
          //格式化节目源 逗号分隔
          for (let i = 0; i < this.channelForm.channelUrlList.length; i++) {
            if (this.channelForm.channelUrlList[i].value != "") {
              if (this.channelForm.channelUrl == "") {
                this.channelForm.channelUrl = this.channelForm.channelUrlList[
                  i
                ].value;
              } else {
                this.channelForm.channelUrl =
                  this.channelForm.channelUrl +
                  "," +
                  this.channelForm.channelUrlList[i].value;
              }
            }
          }
          if (this.channelForm.channelUrl == "") {
            this.$message.warning("请输入节目源地址");
            return false;
          }
          this.channelForm.liveGroupId = this.default_group;
          addChannelApi(this.channelForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoadChannel(this.default_group);
              this.dialogAddChannel = false;
              //清空节目源地址
              this.channelForm.channelUrlList = [{ value: "" }];
              this.channelForm.channelUrl = "";
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加节目源关闭窗口事件
    closeDialogAddChannel() {
      this.$refs["channelForm"].resetFields();
    },
    //添加备用节目源
    addDomain() {
      this.channelForm.channelUrlList.push({
        value: ""
      });
    },
    //打开修改节目源窗口
    openEditChaneel(row) {
      row.channelUrlList = [];
      this.editChannelForm = { ...row };
      var array = row.channelUrl.split(",");
      for (let i = 0; i < array.length; i++) {
        row.channelUrlList.push({
          value: array[i],
          sort: i
        });
      }
      this.dialogEditChannel = true;
    },
    //修改节目源窗口中添加备用节目源
    dialogEditInAddDomain() {
      this.editChannelForm.channelUrlList.push({
        value: "",
        sort: this.editChannelForm.channelUrlList.length
      });
    },
    //删除备用节目源集合
    delChildrenChannel(item) {
      if (this.editChannelForm.channelUrlList.length > 1) {
        var index = this.editChannelForm.channelUrlList.indexOf(item);
        if (index !== -1) {
          this.editChannelForm.channelUrlList.splice(index, 1);
        }
      } else {
        this.$message.warning("至少保留一条节目源地址");
      }
    },
    //上移
    upSortChannel(item) {
      var index = item.sort;
      if (item.sort != 0) {
        this.editChannelForm.channelUrlList[index].sort =
          this.editChannelForm.channelUrlList[index].sort - 1;
        this.editChannelForm.channelUrlList[index - 1].sort =
          this.editChannelForm.channelUrlList[index - 1].sort + 1;
        this.editChannelForm.channelUrlList = this.sortArray(
          this.editChannelForm.channelUrlList,
          "sort"
        );
      }
    },
    //下移
    downSortChannel(item) {
      var index = item.sort;
      if (this.editChannelForm.channelUrlList.length - 1 != item.sort) {
        this.editChannelForm.channelUrlList[index].sort =
          this.editChannelForm.channelUrlList[index].sort + 1;
        this.editChannelForm.channelUrlList[index + 1].sort =
          this.editChannelForm.channelUrlList[index + 1].sort - 1;
        this.editChannelForm.channelUrlList = this.sortArray(
          this.editChannelForm.channelUrlList,
          "sort"
        );
      }
    },
    //修改节目源
    onSubmitEditChannel() {
      this.$refs["editChannelForm"].validate(valid => {
        if (valid) {
          this.editChannelForm.channelUrl = "";
          //格式化节目源 逗号分隔
          for (let i = 0; i < this.editChannelForm.channelUrlList.length; i++) {
            if (this.editChannelForm.channelUrlList[i].value != "") {
              if (this.editChannelForm.channelUrl == "") {
                this.editChannelForm.channelUrl = this.editChannelForm.channelUrlList[
                  i
                ].value;
              } else {
                this.editChannelForm.channelUrl =
                  this.editChannelForm.channelUrl +
                  "," +
                  this.editChannelForm.channelUrlList[i].value;
              }
            }
          }
          if (this.editChannelForm.channelUrl == "") {
            this.$message.warning("请输入节目源地址");
            return false;
          }
          editChannelApi(this.editChannelForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoadChannel(this.default_group);
              this.dialogEditChannel = false;
            }
          });
        } else {
          return false;
        }
      });
    },

    //修改节目源排序
    blurChannelSort(row) {
      editChannelApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoadChannel(this.default_group);
        }
      });
    },
    //修改节目源类型
    changeChannelType(row) {
      editChannelApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoadChannel(this.default_group);
        }
      });
    },
    //修改节目源状态
    changeChannelState(row) {
      editChannelApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoadChannel(this.default_group);
        }
      });
    },
    //删除节目源
    onSubmitDelChannel(row) {
      this.$confirm("此操作将永久删除该节目源, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const channelArray = [];
        channelArray.push(row.channelId);
        delChannelApi({ ids: channelArray }).then(res => {
          this.$message.success(res.msg);
          this.initLoadChannel(this.default_group);
        });
      });
    },
    //节目源勾选菜单事件
    selectionLineChangeChannel(val) {
      const seletedChannelList = [];
      for (let i = 0; i < val.length; i++) {
        seletedChannelList.push(val[i].channelId);
      }
      this.selectionLineChannelData = seletedChannelList;
    },
    //批量删除分类
    batchDelChannel() {
      this.$confirm("此操作将永久删除该节目源, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delChannelApi({ ids: this.selectionLineChannelData }).then(res => {
          this.$message.success(res.msg);
          this.initLoadChannel(this.default_group);
          this.$refs.table.clearSelection();
        });
      });
    },

    //打开修改节目组窗口
    openEditGroup(obj) {
      this.editGroupForm = { ...obj };
      this.dialogEditGroup = true;
      this.searchGroupToShareList(obj.liveGroupId);
    },
    //查询选中组的分享用户集合
    searchGroupToShareList(id) {
      getGroupToShareUserListApi({ liveGroupId: id }).then(res => {
        this.liveGroupUserList = res.data;
      });
    },
    //添加节目组关闭事件
    closeDialogAddGroup() {
      this.liveGroupName = "";
    },
    //添加节目组
    onSubmitAddGroup() {
      addNetworkSourceGroupApi({
        liveGroupName: this.liveGroupName,
        type: "local"
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.dialogAddGroup = false;
          this.initLoadGroup();
        }
      });
    },
    //修改节目组
    onSubmitEditGroup() {
      editNetworkSourceGroupApi({
        liveGroupId: this.editGroupForm.liveGroupId,
        liveGroupName: this.editGroupForm.liveGroupName
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.dialogEditGroup = false;
          this.initLoadGroup();
        }
      });
    },

    //删除节目组
    onSubmitDelGroup(obj) {
      this.$confirm("此操作将永久删除该节目组所有源数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delNetworkSourceGroupApi({ id: obj.liveGroupId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            if (this.groupList.length > 1) {
              if (this.default_group == obj.liveGroupId) {
                this.default_group = this.groupList[0].liveGroupId;
              }
              this.initLoadGroup();
              this.initChannelCategory(this.default_group);
            } else {
              this.default_group = "";
              this.channelList = [];
              this.categoryList = [];
              this.groupList = [];
            }
          }
        });
      });
    },
    //应用节目组
    applicationGroup(obj) {
      applyNetworkSourceGroupApi({
        liveGroupId: obj.liveGroupId,
        groupId: this.placesInfo.groupId,
        type: "local"
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoadGroup();
        }
      });
    },
    //切换节目组
    selectedGroup(obj) {
      this.default_group = obj.liveGroupId;
      this.initChannelCategory(this.default_group);
      this.initLoadChannel(this.default_group);
    },
    //节目组共享给用户
    onSubmitShareGroup() {
      shareGroupApi({
        liveGroupId: this.editGroupForm.liveGroupId,
        mobile: this.editGroupForm.phone
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.searchGroupToShareList(this.editGroupForm.liveGroupId);
        }
      });
    },
    //删除节目组共享用户
    handleGroupClose(obj) {
      this.$confirm("此操作将取消共享该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delShareGroupApi({
          liveGroupId: obj.liveGroupId,
          userId: obj.userId
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.searchGroupToShareList(this.editGroupForm.liveGroupId);
          }
        });
      });
    },
    // 新增分类关闭事件
    closeDialogAddCategory() {
      this.categoryForm.channelTypeName = "";
    },
    //提交添加分类
    onSubmitAddCategory() {
      this.categoryForm.liveGroupId = this.default_group;
      addChannelCategoryApi(this.categoryForm).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initChannelCategory(this.default_group);
          this.dialogAddCategory = false;
          this.categoryForm.channelTypeName = "";
        }
      });
    },
    //分类勾选事件
    selectionLineChangeCategory(val) {
      const seletedCategoryList = [];
      for (let i = 0; i < val.length; i++) {
        seletedCategoryList.push(val[i].channelTypeId);
      }
      this.selectionLineCategoryData = seletedCategoryList;
    },
    //批量删除分类
    batchDelCategory() {
      this.$confirm("此操作将永久删除分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delChannelCategoryApi({ ids: this.selectionLineCategoryData }).then(
          res => {
            this.$message.success(res.msg);
            this.initChannelCategory(this.default_group);
          }
        );
      });
    },
    //删除分类
    onSubmitDelCategory(row) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const objArray = [];
        objArray.push(row.channelTypeId);
        delChannelCategoryApi({ ids: objArray }).then(res => {
          this.$message.success(res.msg);
          this.initChannelCategory(this.default_group);
        });
      });
    },
    //修改分类启用状态
    changeCategoryState(row) {
      row.liveGroupId = this.default_group;
      editChannelCategoryApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initChannelCategory(this.default_group);
        }
      });
    },
    //修改分类排序
    blurCategorySort(row) {
      row.liveGroupId = this.default_group;
      editChannelCategoryApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initChannelCategory(this.default_group);
        }
      });
    },
    //修改分类名称
    //触发焦点记录用户聚焦前的值
    focusCategoryName(row) {
      row.oldChannelTypeName = row.channelTypeName;
    },
    //失去焦点事件
    blurCategoryName(row) {
      row.isCategoryNameSelected = !row.isCategoryNameSelected;
      //判断前后两次的值是否一样
      if (row.channelTypeName !== row.oldChannelTypeName) {
        row.liveGroupId = this.default_group;
        editChannelCategoryApi(row).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initChannelCategory(this.default_group);
          }
        });
      }
    },
    //编辑事件
    cellCategoryClick(row) {
      row.isCategoryNameSelected = !row.isCategoryNameSelected;
    },

    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //分类选择第几页
    handleCategoryCurrentChange(currentPage) {
      this.categoryPage = currentPage;
    },
    //排序
    sortArray(array, key) {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
  }
};
</script>
<style lang='scss'>
.tb_group_cardtitle {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  border-bottom: 1px solid #eee;
}

.div {
  overflow: hidden;
}
.box {
  background: #ffffff;
  padding: 10px 0;
  white-space: nowrap; /*文本不会换行，文本会在在同一行上继续*/
  overflow-y: auto; /*可滑动*/
  scroll-behavior: smooth;
}
/*自定义滚动条的伪对象选择器, CSS 可以隐藏滚动条*/
// .box::-webkit-scrollbar {
//   display: none;
// }

.tv_group_card {
  width: 300px;
  margin-left: 10px;
  display: inline-block;
  border: 1px solid #ebeef5;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.tv_group_card:hover {
  cursor: pointer;
  box-shadow: 0 2px 12px 0 #619aec8c;
  .delGroupClass {
    opacity: 1;
  }
}
.tv_group_card_select {
  background: #f3f7fb;
  .tv_group_bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #025cdf;
    font-size: 12px;
    border-radius: 0 0 8px 8px;
    .el-button--text {
      color: #ffffff;
    }
  }
}
.tv_group_top {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  height: 50px;
  align-items: center;
}
.tv_group_middle {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px;
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.tv_group_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(239, 239, 239);
  font-size: 12px;
  border-radius: 0 0 10px 10px;
}
.tv_group_add_view {
  display: flex;
  justify-content: center;
}
.tv_group_add_icon {
  font-size: 50px;
  color: #8c939d;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.down-tree {
  height: 200px;
  display: block;
  overflow-y: auto;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
.delGroupClass {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -17px;
  top: -7px;
  text-align: center;
  padding-top: 11px;
  box-shadow: 0 1px 1px #ccc;
  opacity: 0;
  cursor: pointer;
}
.localWorkAddress_item {
  position: relative;
}
.localWorkAddress_item:hover {
  .localWorkAddress_operation_btn {
    opacity: 1;
  }
}
.localWorkAddress_operation_btn {
  opacity: 0;
  position: absolute;
  top: -4px;
  right: -25px;
  cursor: pointer;
}
</style>